<template>
  <div>
    <defaultFieldTypes
      :field="field"
      :fieldAttributes="{ ...fieldAttributes, fieldAttrInput: fieldAttrInput }"
      v-on="$listeners"
      :templateContent="result"
    :value="value"></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  data() {
    return { revalidate: false };
  },
  components: { defaultFieldTypes },
  props: ["result", "fieldAttributes", "field","value"],
  watch: {
    "result.valueLine.n_type": function () {
      this.$emit("revalidate");
    },
  },

  computed: {
    type() {
      return this.result.valueLine.n_type;
    },
    fieldAttrInput() {
      let fieldAttrInput = { ...this.fieldAttributes.fieldAttrInput };
      let classInput = fieldAttrInput.class;
      classInput = classInput.replace(/plusPhone/g, "");
      classInput = classInput.replace(/domainUnique/g, "");
      // not supported in old browsers
      //classInput = classInput.replaceAll("plusPhone", "");
      // classInput = classInput.replaceAll("domainUnique", "");
      if (this.type != 2) {
        classInput += " plusPhone";
        classInput += " domainUnique";
      }
      // if (this.storedType != this.type) {
      //   this.$emit("revalidate");
      // }
      // console.log(classInput);
      // if (classInput != this.classInput) {
      //   this.classInput = classInput;
      this.$set(fieldAttrInput, "class", classInput);

      // }
      return fieldAttrInput;
    },
  },
};
</script>